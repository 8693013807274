nav {
    width: max-content;
    display: block;
    padding: 0.7rem 1rem;
    z-index: 2;
    position: fixed;
    left: 1%;
    top: 112px;
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
    backdrop-filter: blur(15px);
}

nav a {
    background: transparent;
    padding: 0.9rem;
    display: flex;
    color: black;
    font-size: 1.1rem;
}

nav a:hover {
    background: rgba(0, 0, 0, 0.3);
}

nav a.active {
    background: var(--color-bg);
    color: var(--color-white);
}

nav h3 {
    color: black;
}