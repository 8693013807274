header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container {
    text-align: left;
    height: 100%;
    position: relative;
    line-height: 2;
    margin: 0;
}

.typewriter {
    justify-content: left;
}

/* ======== CTA =============== */
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

a.btn.btn-primary {
    background-color: #fff;
}

/* ======== HEADER SOCIALS =============== */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.4rem;
    position: absolute;
    right: 0;
    top: 0rem;
    font-size: 3rem;
    margin-left: 5000rem;
}

/* .header__socials::after {
    content:'';
    width: 1px;
    height: 3rem;
    background: #000;
} */

/* ==========SCROLL DOWN============*/
.scroll__down {
    position: absolute;
    right: -1.5rem;
    bottom: 22rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

.top {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    max-width: 50vw;
    margin-inline-start: auto;
    margin-inline-end: auto;
    .textpart {
        padding-right: 5rem;
    };
}

/* =========FONT STYLES=============*/
.ingredients {
    font-weight: 200;
}

span.light_purple {
    color: #da76ff;
}

span.pink {
    color: #ffbadc;
}

span.light_blue {
    color: #5bb2ff;
}

span.grey {
    color: #848297;
}

span.light_green {
    color: #2bbb51;
}

span.brown {
    color: #716565;
}

span.red {
    color: #ce5e5e;
}

span.orange {
    color: #ffb95e;
}

