@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@200;300;400;600&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    max-width: 700px;
    margin-inline-start: auto;
    margin-inline-end: auto;
    line-height: 1.2;
}

:root {
    --color-bg: #1f1f38;
    --color-bg-variant: #4a0202;
    --color-primary: #000000;
    --color-primary-variant: #f99b09;
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6);

    --transition: all 400ms ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}

html {
    scroll-behavior: smooth;
    /*-webkit-font-smoothing: antialiased;*/
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Source Code Pro', monospace;
    background: rgb(21, 21, 21);
    color: var(--color-primary);
    line-height: 1.7;
}

header {
    width: 100%;
    height: 110vh;
    background-size: cover;
    background-position: center;
}

/* ======= GENERAL STYLES =========== */
.container {
    width: var(--container-width-lg);
    margin: 0 auto; /* auto places it in the middle automatically*/

}

h2,
h3,
h4,
h5 {
    font-size: 14px;
    font-weight: 200;
}

h1 {
    font-size: 20px;
    font-weight: 300;
    color: white;
}

h2 {
    color: white;
    font-weight: 300;
}

h3 {
    color: #FFFFFFA3;
}

h4 {
    color: white;
    opacity: 0.5;
    text-align: left;
}

section > h2 {
    text-align:left;
    margin-bottom: 3rem;
}

.text-light {
    color: var(--color-light);
}

a {
    color: white;
    transition: var(--transition);
}

a:hover {
    color: rgb(255, 138, 138);
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.btn:hover {
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary {
    background: var(--color-primary);
    color: var(--color-bg);
}

img {
    display: block;
    width: 20%;
    object-fit: cover;
}

Header {
    height: 35rem;
}

#experience {
    margin-top: 10rem;
    height: 91rem;
    margin: 0px 87.5px;
}

#footer {
    margin-top: 10rem;
    height: 30rem;
    margin: 0px 87.5px;
}
