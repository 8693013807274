.timeline_section {
    display: grid;
    grid-gap: clamp(4px, calc(-5.09px + 2.42vw), 24px);
    grid-template-columns: repeat(4, 1fr);
    text-align: left;
    margin-bottom: 20px;
}

.content {
    grid-column: span 3/span 3;
    text-align: left;
    margin-bottom: 20px;
}

.content img {
    display: inline;
    width: 60px;
    border-radius: 10px;
    margin-bottom: 10px;

}

.content h3 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.dates {
    text-align: left;
}


/* ============ MEDIA QUERIES (MEDIUM DEVICES LIKE TABLETS) ======*/
@media screen and (max-width: 1024px) {
    .experience__container {
        grid-template-columns: 1fr;
    }

    .experience__container > div {
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }

    .experience__content {
        padding: 20px;
    }
}

/* ============ MEDIA QUERIES (SMALL DEVICES LIKE TABLETS) ======*/
@media screen and (max-width: 600px) {
    .experience__container {
        grid-template-columns: 1fr;
    }

    .experience__container > div {
        width: 100%;
        padding: 2rem;
        margin: 0 auto;
    }

    .experience__content {
        padding: 20px;
    }
}