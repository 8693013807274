.portfolio__container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2.5rem;
}

.portfolio__item {
    background: white;
    filter: drop-shadow(0 0 0.75rem rgb(229, 132, 151));
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item:hover {
    border-color: rgb(229, 132, 151);
    background: transparent;
    filter: drop-shadow(0 0 0.75rem white);
}

.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item h3 {
    margin: 1.2rem 0 2rem;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

/* ============ MEDIA QUERIES (MEDIUM DEVICES LIKE TABLETS) ======*/
@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/* ============ MEDIA QUERIES (SMALL DEVICES LIKE TABLETS) ======*/
@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }
}