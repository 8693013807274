#footer h2, h3 {
    margin-bottom: 20px;
}

.contact {
    display: grid;
    grid-gap: clamp(4px, calc(-5.09px + 2.42vw), 24px);
    grid-template-columns: repeat(4, 1fr);
    text-align: left;
    margin-bottom: 0px;
}

.link {
    grid-column: span 3/span 3;
    margin-bottom: 20px;
}

.link img {
    display: inline;
    width: 60px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.link h3 {
    margin-top: 10px;
    margin-bottom: 10px;
    position: inline;
}

.link h4 {
    position: inline;
    margin-bottom: 2px;
}

.site {
    text-align: left;
}